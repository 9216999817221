<template>
  <div>
    <section class="background-topo" id="container">
      <div class="bg"></div>
      <MenuNAV></MenuNAV>
      <b-row align-v="center" class="banner">
        <b-container>
          <b-row>
            <b-col cols="12" md="6" class="text-center">
              <h1>Sua conexão com o mundo</h1>
              <h2>
                Internet via <strong>Fibra Optica</strong> ou
                <strong>Rádio</strong>
              </h2>
              <b-button
                href="#planos"
                squared
                variant="outline-light"
                size="lg"
                class="mt-4"
                >Nossos Planos</b-button
              >
              <b-button squared variant="outline-light" size="lg" class="mt-4"
                ><i class="fab fa-facebook-f"></i
              ></b-button>
              <b-button squared variant="outline-light" size="lg" class="mt-4"
                ><i class="fab fa-instagram"></i
              ></b-button>
              <b-button
                squared
                variant="outline-light"
                size="lg"
                class="mt-4"
                href="https://api.whatsapp.com/send?phone=5599984093756&text=Ol%C3%A1%2C%20tenho%20interesse%20em%20assinar!"
                target="_blank"
                ><i class="fab fa-whatsapp"></i
              ></b-button>
            </b-col>
            <b-col cols="12" md="6" class="text-center">
              <img src="/imgs/banner.png" alt="" class="d-none d-sm-block" />
            </b-col>
          </b-row>
        </b-container>
      </b-row>
    </section>
    <sobrenos></sobrenos>
    <planos></planos>
    <melhorConexao></melhorConexao>
    <contato></contato>
    <rodape></rodape>
  </div>
</template>

<script>
import menu from "../components/menu";
import sobrenos from "../components/sobrenos";
import planos from "../components/planos";
import melhorConexao from "../components/melhorConexao";
import contato from "../components/contato";
import rodape from "../components/footer";
export default {
  name: "Home",
  components: {
    MenuNAV: menu,
    sobrenos,
    planos,
    melhorConexao,
    contato,
    rodape,
  },
};
</script>
