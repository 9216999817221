<template>
  <section class="conteudo" id="sobre-nos">
    <b-row>
      <b-container>
        <b-row>
          <b-col cols="12" md="6">
            <h1 class="titulo">NOVANET Telecom</h1>
            <h2 class="subtitulo">Sua conexão com o mundo</h2>
            <p>
              Em 2010 foi registrada a empresa idealizada por Airton Jorge de
              Sousa. Começamos com uma pequena loja de informática de nome
              AJMTEC'INFOR realizando concertos, formatação, manutenção e vendas
              de peças de computadores e notebooks. Percebemos que estava
              faltando algo, foi aí que surgiu a ideia de trabalhar com
              distribuição de internet. Investimos em um provedor de internet na
              ESPERANÇA de atender as necessidades e oferecer um serviço de
              qualidade aos clientes.
            </p>
            <p>
              Surge então a NOVANET, passamos a trabalhar com internet via
              rádio, Deus abençoou, e agora disponibilizamos fibra óptica na
              cidade. Inovamos o nome da empresa para "NOVANET TELECOM".
              Atendemos clientes de Passagem Franca - MA, continuamos com 2
              lojas de informática. Agradecemos ao nosso Deus pelo crescimento
              da empresa e aos nossos fiéis clientes...
            </p>
          </b-col>
          <b-col cols="12" md="6" class="d-flex justify-content-center">
            <img class="icon-svg zoomInDown" src="/imgs/maoSmart.svg" />
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </section>
</template>

<script>
export default {
  name: "sobre-nos",
};
</script>

<style>
</style>