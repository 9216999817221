<template>
  <div class="menu">
    <b-container>
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#"
          ><img src="/imgs/logo@0-3.png" alt="" srcset=""
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-navbar-nav right>
              <b-nav-item href="#home">Início</b-nav-item>
              <b-nav-item href="#sobre-nos">Sobre nós</b-nav-item>
              <b-nav-item href="#planos">Planos de internet</b-nav-item>
              <b-nav-item href="https://www.speedtest.net/pt" target="_blank"
                >Teste de velocidade</b-nav-item
              >
              <b-nav-item href="#contato">Contato</b-nav-item>
            </b-navbar-nav>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "menu-nav",
};
</script>

<style>
</style>