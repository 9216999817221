<template>
  <section class="conteudo">
    <b-container class="melhor-conexao">
      <b-row>
        <b-col cols="12">
          <h1 class="titulo">A melhor conexão!</h1>
          <hr />
          <h2 class="subtitulo">Conexão de verdade.</h2>
          <b-row class="mt-4 detalhes">
            <b-col cols="12" md="4">
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fab fa-youtube"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Assistir na NETFLIX</h3>
                  <p>
                    Assista aos melhores filmes e séries na maior plataforma de
                    streaming de vídeos do planeta!
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fab fa-twitter"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Participar do Twitter</h3>
                  <p>
                    Compartilhe as melhores hashtags com os assuntos mais
                    interessantes.
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fab fa-whatsapp"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Use o WhatsApp</h3>
                  <p>
                    Converse à vontade com amigos e familiares no maior
                    aplicativo de chat do mundo!
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="4">
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fab fa-facebook-f"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Acessar o Facebook</h3>
                  <p>
                    Assista aos melhores filmes e séries na maior plataforma de
                    streaming de vídeos do planeta!
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fab fa-instagram"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Participar do Instagram</h3>
                  <p>
                    Compartilhe as melhores fotos, publique stories interativos
                    e muito mais...
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fas fa-plus"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>E muito mais!</h3>
                  <p>Está esperando o que? Solicite logo seu plano!</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="4" class="text-center">
              <img src="/imgs/celular.png" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "melhorConexao",
};
</script>

<style>
</style>