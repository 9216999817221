<template>
  <section class="conteudo" id="planos">
    <b-container class="planos">
      <b-row>
        <b-col cols="12">
          <h1 class="titulo">Planos</h1>
          <hr />
          <h2 class="subtitulo tipoPlano">Fibra Óptica</h2>
          <b-row cols="12">
            <b-col
              cols="12"
              md="3"
              v-for="(item, index) in planos.fibra"
              :key="index"
            >
              <div class="quadro">
                <h1>{{ item.megas }} Megas</h1>
                <h2>
                  {{
                    item.valor.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </h2>
                <hr />
                <a
                  :href="`https://api.whatsapp.com/send?phone=5599984093756&text=Ol%C3%A1%2C%20tenho%20interesse%20em%20assinar%20o%20plano%20de%20${item.megas}%20megas%20via%20fibra`"
                  target="_blank"
                >
                  <div class="botao">Contratar</div></a
                >
              </div>
            </b-col>
          </b-row>
          <h2 class="subtitulo mt-3">Rádio</h2>
          <b-row cols="12">
            <b-col
              cols="12"
              md="3"
              v-for="(item, index) in planos.radio"
              :key="index"
            >
              <div class="quadro">
                <h1>{{ item.megas }} Megas</h1>
                <h2>
                  {{
                    item.valor.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </h2>
                <hr />
                <a
                  :href="`https://api.whatsapp.com/send?phone=5599984093756&text=Ol%C3%A1%2C%20tenho%20interesse%20em%20assinar%20o%20plano%20de%20${item.megas}%20megas%20via%20rádio`"
                  target="_blank"
                >
                  <div class="botao">Contratar</div></a
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "planos",
  data() {
    return {
      planos: {
        fibra: [
          { megas: 20, valor: 70 },
          { megas: 40, valor: 90 },
          { megas: 60, valor: 100 },
          { megas: 100, valor: 120 },
          { megas: 150, valor: 160 },
        ],
        radio: [
          { megas: 3, valor: 70 },
          { megas: 4, valor: 80 },
          { megas: 5, valor: 90 },
        ],
      },
    };
  },
};
</script>

<style>
</style>
