<template>
  <footer>
    <b-container>
      <b-row>
        <b-col cols="12" md="5">
          <p class="direitos">
            © Todos os direitos reservados - 2020 NOVANET Telecom
          </p>
        </b-col>
        <b-col cols="12" md="2" class="text-center">
          <img src="/imgs/logo@0-3.png" />
        </b-col>
        <b-col cols="12" md="5">
          <b-row align-h="center">
            <b-button squared variant="outline-light" size="lg" class="mt-1"
              ><i class="fab fa-facebook-f"></i
            ></b-button>
            <b-button squared variant="outline-light" size="lg" class="mt-1"
              ><i class="fab fa-instagram"></i
            ></b-button>
            <b-button
              href="https://api.whatsapp.com/send?phone=5599984093756&text=Ol%C3%A1%2C%20tenho%20interesse%20em%20assinar!"
              target="_blank"
              squared
              variant="outline-light"
              size="lg"
              class="mt-1"
              ><i class="fab fa-whatsapp"></i
            ></b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "rodape",
};
</script>

<style>
</style>