<template>
  <section class="conteudo" id="contato">
    <b-container class="melhor-conexao contato">
      <h1 class="titulo">Entre em contato conosco</h1>
      <hr />
      <h2 class="subtitulo">Responderemos o mais rápido o possível</h2>
      <b-row>
        <b-col cols="12" md="4">
          <b-row class="detalhes">
            <b-col cols="12">
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Endereço</h3>
                  <p>
                    Rua Trav. Airton Sena n° 81, Mutirão, Passagem Franca - MA,
                    65680-000
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="detalhes">
            <b-col cols="12">
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fas fa-phone"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Contato</h3>
                  <p>(99) 98409-3756 | (99) 98412-7218</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="detalhes">
            <b-col cols="12">
              <b-row>
                <b-col cols="3">
                  <div class="circulo-laranja">
                    <i class="fas fa-at"></i>
                  </div>
                </b-col>
                <b-col cols="9">
                  <h3>Email</h3>
                  <p>contato@novanetonline.com.br</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="8" class="pt-3">
          <b-alert variant="success" show v-if="mostrarMensagemSucesso"
            >Obrigado! Aguarde nossa resposta</b-alert
          >
          <b-alert variant="danger" show v-if="envio_erro"
            >Ops! Não foi possível enviar a mensagem</b-alert
          >
          <b-form
            @submit="send"
            v-if="!mostrarMensagemSucesso"
            class="form-contato"
          >
            <b-form-group
              id="form-group-name"
              label="Nome:"
              label-for="form-name"
            >
              <b-form-input
                id="form-name"
                v-model="form.name"
                required
                placeholder="Seu nome completo"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="form-group-email"
              label="Email:"
              label-for="form-email"
            >
              <b-form-input
                id="form-email"
                v-model="form.email"
                type="email"
                required
                placeholder="Seu endereço de email"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="form-group-mensagem"
              label="Mensagem:"
              label-for="form-message"
            >
              <b-form-textarea
                id="form-message"
                v-model="form.message"
                placeholder="Digite aqui a sua mensagem"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-button type="submit" :disabled="mostrarSpin"
              ><b-spinner label="Spinning" v-if="mostrarSpin"></b-spinner
              >{{ !mostrarSpin ? "Enviar" : "" }}</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { dadosSenderEmail } from "../config/config";
const axios = require("axios");
export default {
  name: "contato",
  data() {
    return {
      form: {
        email: "",
        name: "",
        message: "",
      },
      enviado: false,
      enviando: false,
      envio_sucesso: false,
      envio_erro: false,
      show: true,
    };
  },
  methods: {
    send(e) {
      e.preventDefault();
      this.enviando = true;
      this.envio_erro = false;
      this.envio_sucesso = false;

      axios
        .post(dadosSenderEmail.url + "/api/send", {
          name: this.form.name,
          email: this.form.email,
          message: this.form.message,
        })
        .then((response) => {
          if (response.data.success) {
            this.enviando = false;
            this.enviado = true;
            this.envio_sucesso = true;
          } else {
            this.enviando = false;
            this.envio_erro = true;
          }
        });
    },
  },
  computed: {
    mostrarSpin() {
      return this.enviando;
    },
    mostrarMensagemSucesso() {
      return this.enviado && this.envio_sucesso;
    },
  },
};
</script>

<style>
</style>